// import $ from 'jquery';
import 'bootstrap/js/dist/modal';

document.addEventListener('DOMContentLoaded', () => {
    $( '#modal-compare .card-link' ).attr( 'href', '#titulo-secao-compare' );

    let breakpoint_compare = window.matchMedia( '(max-width:28em)' ); // 768 - md

    let produtos_selecionados = [];
    let id_card_selecionado = '';

    $( '.secao-compare-produtos .card-selecione-um-produto' ).on( 'click', function() {
        id_card_selecionado = '#' + $( this ).attr( 'id' );
        // $('#modal-compare').remove();
        $('.modal-backdrop').remove();
        // $('body').removeClass( "modal-open" );

        if( $( id_card_selecionado ).children( '.card-produto' ).length > 0 ) { // Verifica se o card de seleção já possui algum produto
            let produto_desabilitado = $( '#modal-compare #' + $( id_card_selecionado ).children( '.card-produto' ).attr( 'id' ) );
            removerProdutoCardSelecionado( produto_desabilitado.attr( 'id' ) );
        } else {
            $( '#modal-compare .card-produto' ).on( 'click', function() {
                if( produtos_selecionados.indexOf( $( this ) ) > -1 ) { // Remove o produto
                    removerProdutoCardSelecionado( $( this ).attr( 'id' ) );
                    $( '#modal-compare' ).modal('toggle');
                } else { // Adiciona o produto
                    produtos_selecionados.push( $( this ).attr( 'id' ) );
                    $( id_card_selecionado ).removeClass( 'card-inicial' ).html( '' ).append( $( this ).clone() );
                    $( this ).attr( 'disabled', 'disabled' ).addClass( 'disabled' );
                    $( '#modal-compare' ).modal('toggle');
                }
            });
        }
    });

    function removerProdutoCardSelecionado( id_produto ) {
        produtos_selecionados.splice( id_produto, 1 );
        $( id_card_selecionado ).addClass( 'card-inicial' ).html( 'Selecione um<br />Buscopan®' );
        id_produto.removeAttr( 'disabled' ).removeClass( 'disabled' );
    }

    $( '#btn-compare' ).on( 'click', function( event ) {
        $( '.tabela-item' ).removeClass( 'show' );

        produtos_selecionados.forEach( card_produto => {
            let produto = card_produto.replace( 'card-','' );
            console.log( $( '.tabela-item.coluna-' + produto ) );
            $( '.tabela-item.coluna-' + produto ).addClass('show');
        });

        if ( !produtos_selecionados.length ) { // Caso nenhum produto seja selecionado
            $( '#tabela-compare-buscopan' ).removeClass( 'show' );
            $( '.verificacao-compare' ).removeClass( 'd-none' ).addClass( 'd-flex' );
            $( '#btn-compare' ).text('COMPARAR OS PRODUTOS').removeClass( 'btn-outline-primary open' ).addClass( 'btn-primary closed' );

            if( $( '#tabela-compare-buscopan' ).not( '.show' )  ) {	
                produtos_selecionados = [];	
                $( '.form-check-input' ).prop( 'checked', false );
            }

        } else if ( produtos_selecionados.length === 1 ) { // Caso só tenha um produto selecionado
            $( '#tabela-compare-buscopan' ).removeClass( 'show' );
            produtos_selecionados = [];	
            $( '.verificacao-compare' ).text('Selecione mais de um produto para comparar').removeClass( 'd-none' ).addClass( 'd-flex' );
            $( '#btn-compare' ).text('COMPARAR').removeClass( 'btn-outline-primary open' ).addClass( 'btn-primary closed' );

            if( $( '#tabela-compare-buscopan' ).not( '.show' )  ) {	
                produtos_selecionados = [];	
                $( '.card-selecione-um-produto' ).addClass( 'card-inicial' ).html( 'Selecione um<br />Buscopan®' );
                $( '#modal-compare .card-produto' ).removeAttr( 'disabled' ).removeClass( 'disabled' );
            }

        } else { // Caso nenhum produto seja selecionado
            const breakpointChecker = () => {
                if ( breakpoint_compare.matches === true ) { // Scroll para mobile
                    window.scrollBy( 0, 400 );
                } else if ( breakpoint_compare.matches === false ) { // Scroll para desktop
                    $( 'html, body' ).animate( { 
                        scrollTop: 
                            $( '#tabela-compare-buscopan' ).offset().top + ( $( '#tabela-compare-buscopan' ).height() - 150 ) 
                    }, 500 );
                }
            }

            breakpoint_compare.addListener( breakpointChecker );
            breakpointChecker();
            
            $( '#tabela-compare-buscopan' ).toggleClass( 'show' );
            $( '.verificacao-compare' ).addClass( 'd-none' ).removeClass( 'd-flex' );
            $( '#btn-compare' ).text('LIMPAR COMPARAÇÃO').removeClass( 'btn-primary closed' ).addClass( 'btn-outline-primary open' );
            produtos_selecionados = [];	
            $( '.card-selecione-um-produto' ).addClass( 'card-inicial' ).html( 'Selecione um<br />Buscopan®' );
            $( '#modal-compare .card-produto' ).removeAttr( 'disabled' ).removeClass( 'disabled' );

            if( $( '#tabela-compare-buscopan' ).not( '.show' )  ) {  // Quando fechar a tabela de comparação, limpa toda a seleção de produtos
                produtos_selecionados = [];	
                $( '.card-selecione-um-produto' ).addClass( 'card-inicial' ).html( 'Selecione um<br />Buscopan®' );
                $( '#modal-compare .card-produto' ).removeAttr( 'disabled' ).removeClass( 'disabled' );
            }
        }
    });
});