import $ from 'jquery';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import 'bootstrap/js/dist/modal';
import Swiper from 'swiper';
import 'swiper/swiper-bundle.css';
import { Navigation, Pagination } from 'swiper';

window.exibePrecos = exibePrecos;
window.exibeApresentacoes = exibeApresentacoes;

document.addEventListener('DOMContentLoaded', () => {
    $('#btn-onde-comprar').on('click', function() {
        if( $( '#dropdown-menu-getprice' ).is( ':hidden' ) ) {
            $( '#dropdown-menu-getprice' ).slideToggle( 'fast' ).attr( 'hidden', false );         
        } else {
            $( '#dropdown-menu-getprice' ).slideToggle( 'fast' );
        }
    });

    function exibeApresentacoes(idProduto) {
        $.ajax({
            url: "https://getprice.hypera.com.br/api/menor-preco-produto.php",
            method: "POST",
            dataType: "json",
            data: {
                idProduto: idProduto,
            },
            success: function (dados) {
                fecharModalFarmacia();
                $( '#getprice-exibe-apresentacoes' ).html( '' );
                
                if( dados.length < 3 ) {
                    $( '#getprice-exibe-apresentacoes' ).addClass( 'carrossel-centralizado' );
                } else {
                    $( '#getprice-exibe-apresentacoes' ).removeClass( 'carrossel-centralizado' );
                }

                for (var i = 0; i < dados.length; i++) {             
                    if( dados[i].preco == 'R$ 0,00' ) {
                        $( '#getprice-exibe-apresentacoes' ).append(
                            `<article class='swiper-slide card getprice-card btn-getprice' onclick="exibePrecos(${dados[i].id}, this)" disabled><img class='getprice-image' src='https://getprice.hypera.com.br/images/produtos/${dados[i].imagem}'><h3 class='nome-produto'>${dados[i].produto}</span><p class='nome-apresentacao'>${dados[i].apresentacao}</p><p class='nome-apresentacao'>IndisponÃ­vel</p></article>`
                        );
                    } else {
                        $( '#getprice-exibe-apresentacoes' ).append(
                            `<article class='swiper-slide card getprice-card btn-getprice' onclick="exibePrecos(${dados[i].id}, this)"><img class='getprice-image' src='https://getprice.hypera.com.br/images/produtos/${dados[i].imagem}'><h3 class='nome-produto'>${dados[i].produto}</span><p class='nome-apresentacao'>${dados[i].apresentacao}</p><p class='nome-apresentacao'>a partir de<br> <!--<span class='getprice-price'>${dados[i].preco}</span>--></p></article>`
                        );
                    }
                }
            },
        });
    }

    function exibePrecos(idApresentacao, botao) {
        $.ajax({
            url: "https://getprice.hypera.com.br/api/precos-produto.php",
            method: "POST",
            dataType: "json",
            data: {
                idApresentacao: idApresentacao,
            },
            success: function (dados) {
                $( '#getprice-exibe-precos' ).html( '' );

                for (var i = 0; i < dados.length; i++) {              
                    if( dados[i].preco == 'R$ 0,00' ) {
                        $( '#getprice-exibe-precos' ).append(
                            `<div class="col-lg-4 my-2"><div class="card getprice-card card-inativo"><img class="getprice-image" src="https://getprice.hypera.com.br/images/drogarias/${dados[i].imagem}"><span class="nome-drogaria">${dados[i].nome}</span><!--<span class="getprice-price">${dados[i].preco}</span>--><a type="button" class="getprice-link m-4" href="${dados[i].url}?utm_source=site&utm_medium=get-price&utm_id=comprar&produto=&drogaria=" target="_blank" class="getprice-link" disabled>IndisponÃ­vel</a></div></div>`
                        );
                    } else {
                        $( '#getprice-exibe-precos' ).append(
                            `<div class="col-lg-4 my-2"><div class="card getprice-card"><img class="getprice-image" src="https://getprice.hypera.com.br/images/drogarias/${dados[i].imagem}"><span class="nome-drogaria">${dados[i].nome}</span><!--<span class="getprice-price">${dados[i].preco}</span>--><a type="button" class="getprice-link m-4" href="${dados[i].url}?utm_source=site&utm_medium=get-price&utm_id=comprar&produto=&drogaria=" target="_blank" class="getprice-link">Comprar</a></div></div>`
                        );  
                    }
                }

                $( '#getprice-modal .texto-modal-1' ).attr( 'hidden' , true );
                $( '#getprice-exibe-produtos' ).attr( 'hidden', true );
                $( '#getprice-exibe-precos' ).attr( 'hidden', false );

                if( $( botao )[0].classList.contains( 'btn-getprice-precos' ) === false ) {
                    $( '#getprice-modal .texto-modal-2' ).attr( 'hidden', false );
                } else {
                    $( '#getprice-modal .texto-modal-2.texto-getprice' ).attr( 'hidden', false );
                    $( '#voltar-apresentacoes-getprice' ).attr( 'hidden', true );
                }
            },
        });
        
    }

    if( $( '#getprice-exibe-apresentacoes' ).length <= 3 ) {
        $( '#getprice-exibe-apresentacoes .swiper-button-prev' ).attr( 'hidden', true );
        $( '#getprice-exibe-apresentacoes .swiper-button-next' ).attr( 'hidden', true );
        $( '#getprice-exibe-apresentacoes .swiper-pagination' ).attr( 'hidden', true );
    } else {
        $( '#getprice-exibe-apresentacoes .swiper-button-prev' ).attr( 'hidden', false );
        $( '#getprice-exibe-apresentacoes .swiper-button-next' ).attr( 'hidden', false );
        $( '#getprice-exibe-apresentacoes .swiper-pagination' ).attr( 'hidden', false );
    }

    function incluiModal(idMarca) {
        //Cria o dropdown de compra
        let modal = document.createElement('div');
        dropdownCompra.setAttribute('id', 'getprice-modal');
        dropdownCompra.className = 'modal fade';

        //Cira o botÃ£o de Compra
        let botaoCompra = document.createElement("a");
        botaoCompra.setAttribute("id", "dropdown-link-getprice");
        botaoCompra.className = "btn btn-getprice";
        botaoCompra.textContent = "Onde Comprar";
        dropdownCompra.appendChild(botaoCompra);

        //Cria a div do menu de compra
        let divMenuCompra = document.createElement("div");
        divMenuCompra.setAttribute("id", "dropdown-menu-getprice");
        divMenuCompra.className =
            "start-d-none close dropdown-menu-getprice-desktop";
        divMenuCompra.setAttribute("role", "menu");
        divMenuCompra.setAttribute("aria-labelledby", "dropdown-link-getprice");

        //Cria a lista que irÃ¡ armazenar o menu
        let listaMenu = document.createElement("ul");
        listaMenu.className = "submenu submenu-getprice";
        listaMenu.setAttribute("role", "navigation");

        //ObtÃ©m os produtos da marca e gera o link para cada produto
        let data = new FormData();
        data.append("idMarca", idMarca);

        fetch("https://getprice.hypera.com.br/api/lista-produtos.php", { method: "POST", body: data })
            .then((resp) => resp.json())
            .then(function (dados) {
                for (var i = 0; i < dados.length; i++) {
                    //Cria item do menu de compra
                    let itemMenu = document.createElement("li");
                    itemMenu.className = "menu-item";
                    let textoMenu = document.createElement("span");
                    textoMenu.className = "nav-link-desktop";
                    textoMenu.setAttribute("data-bs-toggle", "modal");
                    textoMenu.setAttribute("data-bs-target", "#getprice-modal");
                    textoMenu.setAttribute(
                        "onclick",
                        "exibeApresentacoes(" + dados[i].id + ")"
                    );
                    textoMenu.textContent = dados[i].nome;
                    itemMenu.appendChild(textoMenu);
                    listaMenu.appendChild(itemMenu);
                }
            })
            .catch(function (error) {
                console.log(error);
            });

        dropdownCompra.appendChild(botaoCompra);
        dropdownCompra.appendChild(divMenuCompra);
        divMenuCompra.appendChild(listaMenu);
        dropdownCompra.addEventListener("mouseover", function (event) {
            openDropdownGetPriceDesktop();
        });
        dropdownCompra.addEventListener("mouseout", function (event) {
            closeDropdownGetPriceDesktop();
        });
        let divLocation = document.getElementById("getPriceBotaoDesktop");
        divLocation.appendChild(dropdownCompra);
    }

    function incluiBotaoDesktop(idMarca) {
        //Cria o dropdown de compra
        let dropdownCompra = document.createElement("li");
        dropdownCompra.setAttribute("id", "dropdown-item-getprice");
        dropdownCompra.className = "menu-item dropdown";

        //Cria o botÃ£o de Compra
        let botaoCompra = document.createElement("a");
        botaoCompra.setAttribute("id", "dropdown-link-getprice");
        botaoCompra.className = "btn btn-getprice";
        botaoCompra.textContent = "Onde Comprar";
        dropdownCompra.appendChild(botaoCompra);

        //Cria a div do menu de compra
        let divMenuCompra = document.createElement("div");
        divMenuCompra.setAttribute("id", "dropdown-menu-getprice");
        divMenuCompra.className =
            "start-d-none close dropdown-menu-getprice-desktop dropdown-menu";
        divMenuCompra.setAttribute("role", "menu");
        divMenuCompra.setAttribute("aria-labelledby", "dropdown-link-getprice");

        //Cria a lista que irÃ¡ armazenar o menu
        let listaMenu = document.createElement("ul");
        listaMenu.className = "submenu submenu-getprice";
        listaMenu.setAttribute("role", "navigation");

        //ObtÃ©m os produtos da marca e gera o link para cada produto
        let data = new FormData();
        data.append("idMarca", idMarca);

        fetch("https://getprice.hypera.com.br/api/lista-produtos.php", { method: "POST", body: data })
            .then((resp) => resp.json())
            .then(function (dados) {
                for (var i = 0; i < dados.length; i++) {
                    //Cria item do menu de compra
                    let itemMenu = document.createElement("li");
                    itemMenu.className = "menu-item";
                    let textoMenu = document.createElement("span");
                    textoMenu.className = "nav-link-desktop";
                    textoMenu.setAttribute("data-bs-toggle", "modal");
                    textoMenu.setAttribute("data-bs-target", "#getprice-modal");
                    textoMenu.setAttribute(
                        "onclick",
                        "exibeApresentacoes(" + dados[i].id + ")"
                    );
                    textoMenu.textContent = dados[i].nome;
                    itemMenu.appendChild(textoMenu);
                    listaMenu.appendChild(itemMenu);
                }
            })
            .catch(function (error) {
                console.log(error);
            });

        dropdownCompra.appendChild(botaoCompra);
        dropdownCompra.appendChild(divMenuCompra);
        divMenuCompra.appendChild(listaMenu);
        dropdownCompra.addEventListener("mouseover", function (event) {
            openDropdownGetPriceDesktop();
        });
        dropdownCompra.addEventListener("mouseout", function (event) {
            closeDropdownGetPriceDesktop();
        });
        let divLocation = document.getElementById("getPriceBotaoDesktop");

        //Comentando a linha abaixo o GetPrice deixa de ser exibido nos sites (versÃ£o desktop)
        divLocation.appendChild(dropdownCompra);
    }

    function incluiSeletorMobile(idMarca) {
        //Cria a div flutuante de compra
        let divFlutuante = document.createElement("div");
        divFlutuante.setAttribute("id", "getprice-mobile");
        divFlutuante.setAttribute("draggable", "true");

        //Cira o botÃ£o arrastÃ¡vel
        let botaoArrastavel = document.createElement("span");
        botaoArrastavel.setAttribute("id", "btn-onde-comprar");
        botaoArrastavel.className = "btn btn-getprice-mobile";
        botaoArrastavel.textContent = "Onde Comprar";
        divFlutuante.appendChild(botaoArrastavel);

        //Cria a div do menu de compra
        let divMenuCompraMobile = document.createElement("div");
        divMenuCompraMobile.setAttribute("id", "dropdown-menu-getprice-mobile");
        divMenuCompraMobile.className = "dropdown-menu-mobile";
        divMenuCompraMobile.setAttribute("role", "menu");
        divMenuCompraMobile.setAttribute(
            "aria-labelledby",
            "btn-onde-comprar-mobile"
        );
        divMenuCompraMobile.setAttribute("hidden", "hidden");

        //Cria a lista que irÃ¡ armazenar o menu
        let listaMenuMobile = document.createElement("ul");
        listaMenuMobile.setAttribute("id", "menuMobile");
        listaMenuMobile.className = "submenu-mobile close";
        listaMenuMobile.setAttribute("role", "navigation");

        //ObtÃ©m os produtos da marca e gera o link para cada produto
        let dataMobile = new FormData();
        dataMobile.append("idMarca", idMarca);

        fetch("https://getprice.hypera.com.br/api/lista-produtos.php", {
            method: "POST",
            body: dataMobile,
        })
            .then((resp) => resp.json())
            .then(function (dadosMobile) {
                for (var i = 0; i < dadosMobile.length; i++) {
                    //Cria item do menu de compra
                    let itemMenuMobile = document.createElement("li");
                    itemMenuMobile.className = "menu-item-mobile";
                    let textoMenuMobile = document.createElement("span");
                    textoMenuMobile.className = "nav-link-mobile";
                    textoMenuMobile.setAttribute("data-bs-toggle", "modal");
                    textoMenuMobile.setAttribute(
                        "data-bs-target",
                        "#getprice-modal"
                    );
                    textoMenuMobile.setAttribute(
                        "onclick",
                        "exibeApresentacoes(" + dadosMobile[i].id + ")"
                    );
                    textoMenuMobile.textContent = dadosMobile[i].nome;
                    itemMenuMobile.appendChild(textoMenuMobile);
                    listaMenuMobile.appendChild(itemMenuMobile);
                }
            })
            .catch(function (error) {
                console.log(error);
            });

        divFlutuante.appendChild(botaoArrastavel);
        divFlutuante.appendChild(divMenuCompraMobile);
        divFlutuante.appendChild(listaMenuMobile);
        divFlutuante.addEventListener("click", function (event) {
            openSeletorMobile();
        });
        // /divFlutuante.addEventListener('ondragstart', function (event) { dragando()});
        let divLocationMobile = document.getElementById("getPriceBotaoMobile");

        //Comentando a linha abaixo o GetPrice deixa de ser exibido nos sites (versÃ£o desktop)
        divLocationMobile.appendChild(divFlutuante);
    }

    // Dropdown menu mobile
    function openSeletorMobile() {
        document.querySelector("#menuMobile").classList.toggle("open");
        document.querySelector("#menuMobile").classList.toggle("close");
        document.querySelector("#menuMobile").classList.toggle("start-d-none");
    }

    // Dropdown menu desktop
    function openDropdownGetPriceDesktop() {
        document.querySelector("#dropdown-menu-getprice").classList.add("open");
        document
            .querySelector("#dropdown-menu-getprice")
            .classList.remove("close");
        document
            .querySelector("#dropdown-menu-getprice")
            .classList.remove("start-d-none");
    }

    function closeDropdownGetPriceDesktop() {
        document
            .querySelector("#dropdown-menu-getprice")
            .classList.add("close");
        document
            .querySelector("#dropdown-menu-getprice")
            .classList.remove("open");
        document
            .querySelector("#dropdown-menu-getprice")
            .classList.remove("start-d-none");
    }

    function fecharModalFarmacia() {
        $( '#getprice-modal .texto-modal-2' ).attr( 'hidden', true );
        $( '#getprice-modal .texto-modal-1' ).attr( 'hidden', false );

        $( '#getprice-exibe-precos' ).attr( 'hidden', true );
        $( '#getprice-exibe-produtos' ).attr( 'hidden' , false );

        $( '#getprice-exibe-precos' ).html( '' );
    }

    $( '#voltar-apresentacoes-getprice' ).on( 'click', fecharModalFarmacia );
    $( '#getprice-modal .btn-close' ).on( 'click', fecharModalFarmacia );

    if( $( '#getprice-modal' ).not( '.show' ) ) {
        fecharModalFarmacia();
    }

    const getPriceCarrossel = () => {
        new Swiper('.swiper.carrossel-getprice', {
            slidesPerView: 3,
            spaceBetween: 30,
            breakpoints: {
                340: {
                slidesPerView: 1,
                spaceBetween: 0
                },
                769: {
                slidesPerView: 3,
                spaceBetween: 30
                }
            },
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            },
            pagination: {
                el: '.swiper-pagination',
                type: 'bullets',
                clickable: true,
            },
        });  
    }

    getPriceCarrossel();
});